/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: Don42 (https://sketchfab.com/Don42)
license: CC-BY-SA-4.0 (http://creativecommons.org/licenses/by-sa/4.0/)
source: https://sketchfab.com/3d-models/d20-dice-w20-wurfel-3d-model-free-3b44541db43e4e979efec290ec706cbc
title: D20 Dice (W20 Würfel) 3D model [FREE]
*/

import React, { useEffect, useMemo, useRef } from 'react';
import { useGLTF } from '@react-three/drei';
import d20Model from "./Models/d20_dice_w20_wurfel_3d_model_free.glb";
import { IcosahedronGeometry } from 'three';
import { Physics, useConvexPolyhedron } from '@react-three/cannon';

export default function Model(props) {
  const { nodes, materials } = useGLTF(d20Model);

  const vertices = useMemo(() => {
    const v = [];
    const position = nodes.Icosphere_Main_0.geometry.attributes.position;
    for (let i = 0; i < position.count; i++) {
      v.push([position.getX(i), position.getY(i), position.getZ(i)]);
    }
    return v;
  }, [nodes]);

  const faces = [];

  for (let i = 0; i < vertices.length / 3; i += 3) {
    faces.push([i, i + 1, i + 2]);
  }

  const [ref, api] = useConvexPolyhedron(() => ({
    mass: 1,
    args: [vertices, faces],
    position: [-5, 2, -4]
  }));

  useEffect(() => {
    api.velocity.set(Math.random() * 15, 0, Math.random() * 15);
    api.angularVelocity.set(Math.random() * 10, Math.random() * 10, Math.random() * 10);
  }, [api]);

  return (
    <group ref={ref} {...props} dispose={null}>
      <group rotation={[0, 0, 0]}>
        <group rotation={[0, 0, 0]}>
          <group rotation={[0, 0, 0]} scale={[1, 1, 1]}>
            <mesh geometry={nodes.Icosphere_Main_0.geometry} material={materials.Main} castShadow />
            <mesh geometry={nodes.Icosphere_Letters_0.geometry} material={materials.Letters} />
          </group>
        </group>
      </group>
    </group>
  )
}

useGLTF.preload(d20Model)
