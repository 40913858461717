import "./LLMMenu.scss";
import { Canvas, useThree } from '@react-three/fiber'
import D20 from './Dice/D20';
import { Environment, OrbitControls, OrthographicCamera } from '@react-three/drei';
import { Physics, Triplet, useBox, usePlane } from "@react-three/cannon";
import { ShadowMaterial } from "three";

const LLMMNenuComponent = () => {
    return <div className="canvas-container">
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>
        <h1>TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST! TEST!</h1>

        <Canvas shadows className="canvas">
            <OrthographicCamera makeDefault position={[0, 10, 0]} rotation={[-Math.PI / 2, 0, 0]} zoom={100}/>
            
            <ambientLight intensity={Math.PI / 2} />
            <directionalLight
                position={[5, 5, 5]}
                intensity={1}
                castShadow
                shadow-mapSize-width={1024}
                shadow-mapSize-height={1024}
                shadow-camera-far={15}
                shadow-camera-near={0.1}
                shadow-camera-left={-10}
                shadow-camera-right={10}
                shadow-camera-top={10}
                shadow-camera-bottom={-10}
            />
            
            <Environment preset="studio"/>
            <OrbitControls/>
            
            <Physics>
                <D20 position={[0, 0, 0]}/>
                <Ground/>
                <Walls/>
            </Physics>
        </Canvas>;
    </div>
};

const ShadowOnlyMaterial = new ShadowMaterial({opacity: 0.5});
const Ground = () => {
    const [ref] = usePlane(() => ({
        rotation: [-Math.PI / 2, 0, 0],
        position: [0, 0, 0]
    }));

    return <object3D ref={ref}>
        <mesh receiveShadow>
            <planeGeometry args={[100, 100]} />
            <primitive object={ShadowOnlyMaterial} attach="material"/>
        </mesh>
    </object3D>
};

const Wall = ({ position, rotation}) => {
    const dimensions: Triplet = [1, 20, 20];
    const [ref] = useBox(() => ({
        position,
        rotation,
        args: dimensions,
        type: 'Static'
    }));

    return (<object3D ref={ref}>
        <mesh>
            <boxGeometry args={dimensions} />
            {/* <meshBasicMaterial color="hotpink" opacity={1} /> */}
        </mesh>
    </object3D>);
}

const Walls = () => {
    const { camera, size } = useThree();
    const aspect = size.width / size.height;
    const viewWidth = 10 / camera.zoom;
    const viewHeight = (viewWidth / aspect) + 0.5;

    return (
        <>
            <Wall position={[viewWidth, 5, 0]} rotation={[0, 0, 0]} />
            <Wall position={[-viewWidth, 5, 0]} rotation={[0, 0, 0]} />
            <Wall position={[0, 5, -viewHeight]} rotation={[0, Math.PI / 2, 0]} />
            <Wall position={[0, 5, viewHeight]} rotation={[0, Math.PI / 2, 0]} />
        </>
    );
};

export default LLMMNenuComponent;